import React, { FunctionComponent, ReactElement } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { SEO, GeneralJumbotron } from '../components';
import { MainLayout } from '../layouts';
import { MarketingImagesQuery } from '../shared/interfaces';

const MarketingPage: FunctionComponent = (): ReactElement => {
    const queryResult = useStaticQuery<MarketingImagesQuery>(graphql`
        query {
            marketingBackgroundImage: file(name: {eq: "marketingBackground"}, extension: {eq: "jpg"}) {
                publicURL
            }
        }
    `);

    return (
        <MainLayout>
            <SEO title="PCH | Marketing"/>
            <GeneralJumbotron
                title="Marketing"
                backgroundImageURL={queryResult?.marketingBackgroundImage.publicURL}
                backgroundText="Marketing"
            />
        </MainLayout>
    );
}

export default MarketingPage;
